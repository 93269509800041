import React from 'react';
import styles from './main.module.css';
import Button from '../../components/button/Button';
import PageBg from '../../components/pageBg/PageBg';
import ShadowBottom from '../../components/shadowBottom/shadowBottom';
import { TScrollPage } from '../../utils/types';

import main_blur_bg from '../../images/main_bg_blur.svg';
import sparks from '../../images/sparks.png';
import main_person_blur from '../../images/main_person_blur.png';
import main_person from '../../images/main_person.png';
import amazing_title from '../../images/amazing_title.svg';


const Main: React.FC<TScrollPage> = ({reference}) => {
  const [index, setIndex] = React.useState<number>(0);
  const data = [
    {
      title: 'Ролеплей проект на платформе Arma 3',
      description: 'Каждый может попробовать себя в различных ролях и взаимодействовать с другими игроками в реальном времени'
    },
    {
      title: 'Ролеплей сервер #1 (В россии и СНГ)',
      description: 'Исходя из статистики www.battlemetrics.com'
    },
    {
      title: 'Уникальный мод',
      description: 'Каждый аспект был тщательно проработан нашей командой, чтобы предоставить вам неповторимый игровой опыт'
    }
  ];

  React.useEffect(() => {
    setTimeout(() => {
      if (index === (data.length - 1)) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }
      
    }, 10000)
  }, [index]);

  const goToInstall = () => {
    const section = document.getElementById('howtoinstall');
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <PageBg 
      type='main'
      radial='bottom'
      id='main'
      reference={reference}
      opacity={1}
    >
      <img className={styles.blur_bg} src={main_blur_bg} alt="" />
      <img className={styles.sparks} src={sparks} alt="" />
      <img className={styles.person_blur} src={main_person_blur} alt="" />
      <img className={styles.person} src={main_person} alt="" />
      <ShadowBottom/>

      <div className={styles.textContainerMain}>
        <div className={styles.textContainerBg}></div>
        <img className={styles.amazingTitle} src={amazing_title} alt="" />
        <div className={styles.textContainer}>
          <h1 className={styles.title}>{data[index].title}</h1>
          <p className={styles.description}>{data[index].description}</p>
        </div>
        <div className={styles.slideBtnContainter}>
          <button onClick={() => {setIndex(0)}} className={`${styles.slideBtn} ${index === 0 ? styles.slideBtn_active : ""}`}></button>
          <button onClick={() => {setIndex(1)}} className={`${styles.slideBtn} ${index === 1 ? styles.slideBtn_active : ""}`}></button>
          <button onClick={() => {setIndex(2)}} className={`${styles.slideBtn} ${index === 2 ? styles.slideBtn_active : ""}`}></button>
        </div>
      </div>


      <div className={styles.container}>
        <Button size={'medium'} color={'orange'} text={'normal'} borderRadius={15} type="button" disable={false} onClick={goToInstall}>НАЧАТЬ ИГРАТЬ</Button>
      </div>
    </PageBg>
  );
}

export default Main;

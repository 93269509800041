import React from 'react';
import styles from './advantages.module.css';
import PageBg from '../../components/pageBg/PageBg';
import ShadowBottom from '../../components/shadowBottom/shadowBottom';
import ShadowTop from '../../components/shadowTop/shadowTop';

import { TScrollPage } from '../../utils/types';

const Advantages: React.FC<TScrollPage> = ({reference}) => {
  const [index, setIndex] = React.useState<number>(0);
  
  const data = [
    {
      title: 'Огромный Виртуальный Мир',
      description: 'Представьте себе огромный остров, вдохновленный живописными пейзажами Греции. Его впечатляющая территория, охватывающая 270 квадратных километров, готова воплотить все ваши идеи и фантазии в реальность. От захватывающих горных вершин до изумительных пляжей, от живописных деревень до современных городов – здесь каждый найдет что-то по своему вкусу.',
      image: '2'
    },
    {
      title: 'Уютное место',
      description: 'Мы настойчиво придерживаемся принципа отсутствия токсичности на нашем проекте. Здесь вы сможете насладиться вечером в уютной компании у дружественного костра. Наше сообщество собрано из людей, готовых поддержать друг друга и провести время в приятной атмосфере, наслаждаясь красотами окружающего мира.',
      image: '3'
    },
    {
      title: 'Зона Падения Метеорита',
      description: 'Погрузитесь в роль исследователя, получившего возможность изучить последствия падения метеорита. Отправляйтесь в опасные экспедиции, исследуя окружающую местность, встречая уникальные формы жизни и раскрывая тайны аномалий. Однако будьте бдительны: местная флора и фауна не любит гостей, и каждый шаг может стать последним.',
      image: '4'
    },
    {
      title: 'Совершенная Система Недвижимости',
      description: 'Наша тщательно проработанная система недвижимости включает десятки улучшений, от предметов интерьера до биткоин-ферм для создания пассивного дохода. Создавайте собственные уютные уголки, развивайте собственное дело или инвестируйте в недвижимость, чтобы стать влиятельным жителем на острове.',
      image: '5'
    },
    {
      title: 'Разнообразие Транспорта',
      description: 'С более чем сотней транспортных средств каждый найдет себе подходящий вариант передвижения. От классических автомобилей до леветирующего, от мощных катеров до уютных велосипедов – у нас есть все, чтобы сделать ваше путешествие по острову комфортным и захватывающим.',
      image: '1'
    },
    {
      title: 'Анимации и Механики',
      description: 'У нас есть сотни различных анимаций и жестов, которые помогут вам в самовыражении, а специальные механики упростят ваше взаимодействие с другими игроками. Воплотите свои самые смелые идеи, встречайтесь с новыми друзьями и создавайте неповторимые моменты, которые будут запоминаться навсегда.',
      image: '6'
    },
    {
      title: 'Крафт, Бартер и Торговля',
      description: 'Создавайте, обменивайтесь и продавайте уникальные предметы на нашей бартерной зоне и торговой площадке. Наши вендинговые аппараты предоставляют обширный список возможностей, позволяя вам эффективно управлять своими товарами и увеличивать свои доходы.',
      image: '7'
    },
    {
      title: 'Опытная Поддержка',
      description: 'Наши опытные администраторы всегда готовы помочь вам в трудные моменты, гарантируя вам надежную поддержку. Мы стремимся к тому, чтобы каждый пользователь чувствовал себя в безопасности и в комфорте на нашем острове, и делаем все возможное, чтобы сделать ваше время здесь незабываемым и приятным.',
      image: '8'
    },
    {
      title: 'Ресурсы и навыки',
      description: 'У нас реализована продуманная система развития персонажа, которая открывает перед вами новые ресурсы по мере увеличения вашего навыка в той или иной профессии. Например, начав свой путь с добычи кварца, вы постепенно продвигаетесь дальше и обнаруживаете возможность искать алмазы, а модернизированная система добычи ресурсов упрощает процесс, позволяя вам мгновенно отправлять ресурсы в транспорт, минимизируя потери времени и сил.',
      image: '9'
    },
    {
      title: 'Еженедельные События',
      description: 'Каждую неделю на острове проходят эпические битвы за ресурсы (Battle for domination) или оружейный рынок (Warzone). Кроме того, ежедневно вас ждут сражения за районы (war for district) и возможность ограбить банк или федеральный резерв. Разнообразие мероприятий гарантирует, что у вас всегда будет что-то новое и увлекательное, чтобы занять ваше время на острове.',
      image: '10'
    }
  ];

  const goToLeft = () => {
    if (index <= 0) {
      setIndex(data.length - 1)
    } else {
      setIndex(index - 1)
    }
  }
  const goToRight = () => {
    if (index >= (data.length - 1)) {
      setIndex(0)
    } else {
      setIndex(index + 1)
    }
  }
  const getIndex = (inAdd: number) => {
    let newIndex = index + inAdd;
    if (newIndex >= data.length) {
      newIndex = newIndex - data.length;
    }
    return(newIndex);
  }

  return (
    <PageBg 
      type='advantages'
      radial='top'
      id='advantages'
      reference={reference}
    >
      <ShadowTop/>
      <ShadowBottom/>
      <div className={styles.mainContainer}>
        <div className={styles.containerText}>
          <h2 className={styles.title}>{data[index].title}</h2>
          <div className={styles.line} />
          <p className={styles.description}>{data[index].description}</p>
        </div>
        <div className={styles.imageContainer}>
        <button 
          className={styles.btnLeft} 
          onClick={goToLeft}
          type='button'
        />
        <button 
          className={styles.btnRight} 
          onClick={goToRight}
          type='button'
        />
          {
            data.map((inItem, inIndex, inArray) => {

              let styleImg = styles.img4;
              if (inIndex === index) {
                styleImg = styles.img1;
              }
              if (inIndex === getIndex(1)) {
                styleImg = styles.img2;
              }
              if (inIndex === getIndex(2)) {
                styleImg = styles.img3;
              }

              return(
                <img alt='' key={`${inItem.image}`} className={`${styles.img} ${styleImg}`} src={require(`../../images/advantages/${inItem.image}.png`)} />
              );
            })
          }
        </div>
      </div>
      <div className={styles.scrollContainer}>
        <p className={styles.scrollText}>сделай</p>
        <span className={styles.loaderMouse}></span>
        <p className={styles.scrollText}>выбор</p>
      </div>
    </PageBg>
  );
}

export default Advantages;

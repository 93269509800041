import React from 'react';
import styles from './input.module.css';

import icon_checkbox from '../../images/donate/icon_checkbox.svg';
import icon_warning from '../../images/donate/icon_warning.svg';

import icon_wallet_orange from '../../images/donate/icon_wallet_orange.svg';
import icon_person_orange from '../../images/donate/icon_person_orange.svg';
import icon_mail_orange from '../../images/donate/icon_mail_orange.svg';
import icon_count_orange from '../../images/donate/icon_count_orange.svg';

import icon_wallet_green from '../../images/donate/icon_wallet_green.svg';
import icon_person_green from '../../images/donate/icon_person_green.svg';
import icon_mail_green from '../../images/donate/icon_mail_green.svg';
import icon_count_green from '../../images/donate/icon_count_green.svg';

import icon_wallet_red from '../../images/donate/icon_wallet_red.svg';
import icon_person_red from '../../images/donate/icon_person_red.svg';
import icon_mail_red from '../../images/donate/icon_mail_red.svg';
import icon_count_red from '../../images/donate/icon_count_red.svg';
import { TInput } from '../../utils/types';

const Input: React.FC<TInput> = ({type, name, placeHolder, onChange, value, icon, extraClass, isValid, focus, focusIn, focusOut, disabled = false}) => {
  let resultIcon;
  switch (icon) {
    case 'wallet':
      resultIcon = (isValid ? (icon_wallet_green) : (((value !== '') && !focus) ? icon_wallet_red : icon_wallet_orange));
      break;
    case 'person':
      resultIcon = (isValid ? (icon_person_green) : (((value !== '') && !focus) ? icon_person_red : icon_person_orange));
      break;
    case 'mail':
      resultIcon = (isValid ? (icon_mail_green) : (((value !== '') && !focus) ? icon_mail_red : icon_mail_orange));
      break;
    case 'count':
      resultIcon = (isValid ? (icon_count_green) : (((value !== '') && !focus) ? icon_count_red : icon_count_orange));
      break;
    default:
      resultIcon = icon_wallet_orange;
      break;
  };

  return (
    <div className={`${styles.inputContainer} ${extraClass}`}>
        <div className={`${styles.inputField} ${isValid ? styles.valid : styles.invalid} ${focus ? styles.focused : ''}`}>
            <img src={resultIcon} alt="" className={styles.leftImage} />
            {(isValid || ((value !== '') && !focus)) && 
              (isValid ? (
                <img src={icon_checkbox} alt="" className={styles.rightImage} />
              ) : (
                <img src={icon_warning} alt="" className={styles.rightImage} />
              ))
            }
            <input 
                type={type}
                name={name}
                placeholder={placeHolder}
                value={value} 
                onChange={onChange} 
                onFocus={focusIn} 
                onBlur={focusOut} 
                className={styles.inputText} 
                disabled={disabled}
            />
        </div>
    </div>
  );
}

export default Input;

import React from 'react';
import styles from './notification.module.css';
import { TNotification } from '../../utils/types';

import copy_icon from '../../images/notification/copy_icon.svg';
import done_icon from '../../images/notification/done_icon.svg';
import info_icon from '../../images/notification/info_icon.svg';
import error_icon from '../../images/notification/error_icon.svg';

import copy_close from '../../images/notification/copy_close.svg';
import done_close from '../../images/notification/done_close.svg';
import info_close from '../../images/notification/info_close.svg';
import error_close from '../../images/notification/error_close.svg';

const Notification: React.FC<TNotification> = ({type, text, close}) => {
  let styleNotification;
  let styleText;
  let styleLine;
  let iconClose;
  let icon;
  switch (type) {
    case "copy":
      styleNotification = styles.notificationCopy;
      styleText = styles.textCopy;
      styleLine = styles.lineCopy;
      iconClose = copy_close;
      icon = copy_icon;
      break
    case "done":
      styleNotification = styles.notificationDone;
      styleText = styles.textDone;
      styleLine = styles.lineDone;
      iconClose = done_close;
      icon = done_icon;
      break
    case "info":
      styleNotification = styles.notificationInfo;
      styleText = styles.textInfo;
      styleLine = styles.lineInfo;
      iconClose = info_close;
      icon = info_icon;
      break
    case "error":
      styleNotification = styles.notificationError;
      styleText = styles.textError;
      styleLine = styles.lineError;
      iconClose = error_close;
      icon = error_icon;
      break
    default:
      styleNotification = styles.info;
      styleText = styles.textInfo;
      styleLine = styles.lineInfo;
      iconClose = info_close;
      icon = info_icon;
      break
  };

  return (
    <div className={`${styles.notification} ${styleNotification}`}>
      <img className={styles.icon} src={icon} alt="" />
      <p className={`${styleText} ${styles.text}`}>{text}</p>
      <span className={`${styles.line} ${styleLine}`}/>
      <img className={styles.close} src={iconClose} onClick={close} alt="Закрыть" />
    </div>
  );
}

export default Notification;

import React from 'react';
import styles from './button.module.css';
import { TButton } from '../../utils/types';

const Button: React.FC<TButton> = ({onClick, children, size, color, text, borderRadius, type, disable}) => {
  let styleSize;
  switch (size) {
    case 'small':
      styleSize = styles.small;
      break
    case 'medium':
      styleSize = styles.medium;
      break
    case 'large':
      styleSize = styles.large;
      break
    default:
      styleSize = styles.medium;
      break
  };

  let styleColor;
  switch (color) {
    case 'orange':
      styleColor = styles.orange;
      break
    case 'green':
      styleColor = styles.green;
      break
    case 'gold':
      styleColor = styles.gold;
      break
    case 'blue':
      styleColor = styles.blue;
      break
    case 'red':
      styleColor = styles.red;
      break
    case 'olive':
      styleColor = styles.olive;
      break
    case 'violet':
      styleColor = styles.violet;
      break
    case 'blackOrange':
      styleColor = styles.blackOrange;
      break
    default:
      styleColor = styles.orange;
      break
  };

  let styleText;
  switch (text) {
    case 'light':
      styleText = styles.light;
      break
    case 'normal':
      styleText = styles.normal;
      break
    case 'medium':
      styleText = styles.textMedium;
      break
    default:
      styleText = styles.normal;
      break
  };

  return (
    <button 
      className={`${styles.btn} ${styleText} ${styleColor} ${styleSize}`} 
      style={{ borderRadius: borderRadius }}
      onClick={onClick}
      type={type}
      disabled={disable}
    >
      {children}
    </button>
  );
}

export default Button;

import React from 'react';
import styles from './logo.module.css';
import logo_bg from '../../images/logo_bg.svg'
import logo_letter from '../../images/logo_letter.svg'

function Logo() {
  return (
    <div className={styles.container}>
      <img className={styles.logo_bg} src={logo_bg} alt="" />
      <img className={styles.logo_letter} src={logo_letter} alt="" />
    </div>
  );
}

export default Logo;

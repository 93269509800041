import React from 'react';
import styles from './howtoinstall.module.css';
import ShadowTop from '../../components/shadowTop/shadowTop';
import ShadowBottom from '../../components/shadowBottom/shadowBottom';
import CardButton from '../../components/cardButton/CardButton';
import Card from '../../components/card/Card';
import PageBg from '../../components/pageBg/PageBg';

import person from '../../images/cards/person.png';
import sign from '../../images/cards/sign.png';

import steps_1 from '../../images/cards/steps_1.svg';
import steps_2 from '../../images/cards/steps_2.svg';
import { TScrollPage } from '../../utils/types';

const HowToInstall: React.FC<TScrollPage> = ({reference}) => {
  const [videoReady, setVideoReady] = React.useState(false);
  const targetRef = React.useRef(null);

  const goToVideoHelp = () => {
    window.open(
      'https://youtu.be/e2GpA1zxVIs',
      '_blank'
    );
  }
  const goToBuyArma3 = () => {
    window.open(
      'https://store.steampowered.com/app/107410/Arma_3/',
      '_blank'
    );
  }
  const downloadTeamSpeak3 = () => {
    const filePath = 'https://amazingrp.ru/files/teamspeak3.zip';
    window.location.href = filePath;
  }
  const connectToTeamSpeak3 = () => {
    window.location.href = 'ts3server://ts.amazingrp.ru';
  }
  const downloadLauncher = () => {
    const filePath = 'https://amazingrp.ru/files/AmazingLife-1.1.0-win64-setup.exe';
    window.location.href = filePath;
  }
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const timeout = setTimeout(() => {
            setVideoReady(true);
            clearTimeout(timeout);
          }, 5000);
          observer.unobserve(entry.target);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  return (
    <PageBg
     type='howtoinstall'
     radial='empty'
     id='howtoinstall'
     reference={reference}
    >
      <ShadowTop />
      <ShadowBottom />
      <h2 className={styles.title}>Как начать играть</h2>
      <div className={styles.line}></div>
      <img alt='' className={`${styles.person} ${videoReady ? styles.person_active : ''}`} src={person} />
      <img alt='' className={`${styles.sign} ${videoReady ? styles.sign_active : ''}`} src={sign} onClick={goToVideoHelp}/>
      <div className={styles.cardContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.bgBlue} />
          <Card
            type="blue"
            size="small"
            step="Шаг 1"
            title="ПРИОБРЕСТИ ИГРУ"
            description={<>Купите и установите лицензионую версию Arma 3</>}
          >
            <CardButton
              onClick={goToBuyArma3}
              color="blue"
              icon="steam"
              type="button"
              size="medium"
              disable={false}
            >
              Купить
            </CardButton>
          </Card>
        </div>
        <div className={styles.centerContainer}>
          <div className={styles.bgViolet} />
          <Card
            type="violet"
            size="large"
            step="Шаг 2"
            title="УСТАНОВИТЬ TEAMSPEAK 3"
            description={<>Скачайте и установите TeamSpeak 3 с плагином TaskForce<br />Затем присоединитесь на сервер <span className={styles.link}>ts.amazingrp.ru</span></>}
          >
            <CardButton
              onClick={downloadTeamSpeak3}
              color="violet"
              icon="microphone"
              type="button"
              size="medium"
              disable={false}
            >
              Установить
            </CardButton>
            <CardButton
              onClick={connectToTeamSpeak3}
              color="violet"
              icon="teamspeak"
              type="button"
              size="small"
              disable={false}
            />
          </Card>
        </div>
        <div ref={targetRef} className={styles.rightContainer}>
          <div className={styles.bgRed} />
          <Card
            type="red"
            size="small"
            step="Шаг 3"
            title="СКАЧАТЬ ЛАУНЧЕР"
            description={<>Загрузите модификацию, затем присоединитесь к серверу<br />s1.amazingrp.ru (Порт: 2302)</>}
          >
            <CardButton
              onClick={downloadLauncher}
              color="red"
              icon="download"
              type="button"
              size="medium"
              disable={false}
            >
              Скачать
            </CardButton>
          </Card>
        </div>
        <img alt='' className={styles.steps_1} src={steps_1} />
        <img alt='' className={styles.steps_2} src={steps_2} />
      </div>
    </PageBg>
  );
}

export default HowToInstall;
import React from 'react';
import styles from './appheader.module.css';
import Button from '../button/Button';
import Logo from '../logo/Logo';
import NavButton from '../navbutton/NavButton';
import { useNavigate, useLocation } from "react-router-dom";

function AppHeader() {
  const navigate = useNavigate();
  const goToDonate = () => {
    navigate('/donate');
  }

  const location = useLocation();
  const windowWidth = React.useRef(window.innerWidth);
  
  return (
    <nav className={styles.nav}>
      <ul className={styles.ul}>
        <li className={styles.li}><a href="/"><Logo/></a></li>
        <div className={styles.rightContainer}>
        <li className={`${styles.li}`}><NavButton image='forum' link="https://amazingrp.ru/forum/">ФОРУМ</NavButton></li>
          <li className={`${styles.li} ${styles.ml3}`}><NavButton image='key' link="https://lk.amazingrp.ru/login">ЛИЧНЫЙ КАБИНЕТ</NavButton></li>
          {!((location.pathname === '/donate') && (windowWidth.current < 720)) &&
            <li className={`${styles.li} ${styles.ml6}`}>
              <Button
                onClick={goToDonate}
                size={'small'}
                color={'gold'}
                text={'light'}
                borderRadius={6}
                type="button"
                disable={false}
              >
                ПОПОЛНИТЬ СЧЁТ
              </Button>
            </li>
          }
        </div>
      </ul>
    </nav>
  );
}

export default AppHeader;

import React from 'react';
import styles from './scrollbar.module.css';
import { TScrollbar } from '../../utils/types';

const Scrollbar: React.FC<TScrollbar> = ({page}) => {

  const scrollMe = (target: string) => {
    const section = document.getElementById(target);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <ul className={styles.ul}>
      <li className={`${styles.li} ${page === 1 ? styles.li_active : ""}`}><button onClick={() => {scrollMe('main')}} className={styles.button}/></li>
      <li className={`${styles.li} ${page === 2 ? styles.li_active : ""}`}><button onClick={() => {scrollMe('advantages')}} className={styles.button}/></li>
      <li className={`${styles.li} ${page === 3 ? styles.li_active : ""}`}><button onClick={() => {scrollMe('slidercontainer')}} className={styles.button}/></li>
      <li className={`${styles.li} ${page === 4 ? styles.li_active : ""}`}><button onClick={() => {scrollMe('howtoinstall')}} className={styles.button}/></li>
    </ul>
  );
}

export default Scrollbar;

import React from 'react';
import styles from './pagebg.module.css';
import { TPageBg } from '../../utils/types';

import main_bg from '../../images/main_bg.png';
import radial_picture from '../../images/radial.png';
import advantages_bg from '../../images/advantages/bg.png'
import donate_bg from '../../images/donate_bg.png'
import notfound_bg from '../../images/notfound_bg.png'

import howtoinstall_bg_1 from '../../images/howtoinstall_bg_1.png'
import howtoinstall_bg_2 from '../../images/howtoinstall_bg_2.png'

const PageBg: React.FC<TPageBg> = ({children, type, radial, id, reference, opacity = 0.35}) => {
  let BgPicture;
  switch (type) {
    case 'main':
      BgPicture = main_bg;
      break
    case 'advantages':
      BgPicture = advantages_bg;
      break
    case 'donate':
      BgPicture = donate_bg;
      break
    case 'slider':
      BgPicture = howtoinstall_bg_1;
      break
    case 'howtoinstall':
      BgPicture = howtoinstall_bg_2;
      break
    case 'notfound':
      BgPicture = notfound_bg;
      break
    default:
      BgPicture = main_bg;
      break
  };

  let radialStyle;
  switch (radial) {
    case 'top':
      radialStyle = styles.radial_top;
      break
    case 'bottom':
      radialStyle = styles.radial_bottom;
      break
    default:
      radialStyle = styles.radial_top;
      break
  };

  return (
    <section className={styles.section} id={id} ref={reference}>
      <img alt='' className={styles.bg} src={BgPicture} style={{opacity: opacity}}/>
      { radial !== 'empty' &&
        <img alt='' className={`${styles.radial} ${radialStyle}`} src={radial_picture} />
      }
      {children}
    </section>
  );
}

export default PageBg;

const url = 'https://api.amazingrp.ru/';

export const checkResponse = (res: Response) => (res.ok ? res.json() : res.json().then((error: any) => Promise.reject(error)));

export const getPaymentTypes = () => {
  return fetch(
    `${url}api/v1/payment/systems`,
    {
      method: 'GET',
    }
  )
  .then(checkResponse);
};

export const getUserId = (id: string) => {
  return fetch(
    `${url}api/v1/player/${id}`,
    {
      method: 'GET',
    }
  )
  .then(checkResponse);
};

export const getInvoice = (type: string, amount: number, steamid: string, email: string) => {
  console.log(JSON.stringify({
    amount: amount,
    steam_id: steamid,
    email: email
  }));

  return fetch(
    `${url}api/v1/payment/${type}/invoice`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        amount: amount,
        steam_id: steamid,
        email: email
      })
    }
  )
  .then(checkResponse);
};

export const getServerState = () => {
  return fetch(
    `${url}api/v1/server/info`,
    {
      method: 'GET',
    }
  )
  .then(checkResponse);
};
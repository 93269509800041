import React from 'react';
import styles from './shadowtop.module.css';

function ShadowTop() {
  return (
    <div className={styles.shadow}></div>
  );
}

export default ShadowTop;

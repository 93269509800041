import React from 'react';
import styles from './shadowbottom.module.css';

function ShadowBottom() {
  return (
    <div className={styles.shadow}></div>
  );
}

export default ShadowBottom;

import React from 'react';
import styles from './slider.module.css';
import { TSlider } from '../../utils/types';

const Slider: React.FC<TSlider> = ({translate, setActive, title, line, description, button}) => {
  const hoverHandlerEnter = () => {
    setActive(true);
  }
  const hoverHandlerExit = () => {
    //setActive(false);
  }

  let styleColor;
  switch (line) {
    case 'green':
      styleColor = styles.line_green;
      break
    case 'blue':
      styleColor = styles.line_blue;
      break
    case 'red':
      styleColor = styles.line_red;
      break
    case 'olive':
      styleColor = styles.line_olive;
      break
    case 'violet':
      styleColor = styles.line_violet;
      break
    default:
      styleColor = styles.line_green;
      break
  };

  return (
    <article
      className={styles.article}
      style={{translate: `${translate}%`}}
    >
      <h2 className={styles.title}>{title}</h2>
      <div className={`${styles.line} ${styleColor}`}></div>
      <p className={styles.description}>{description}</p>
      <div className={styles.buttonContainer} onMouseEnter={hoverHandlerEnter} onMouseLeave={hoverHandlerExit}>
        {button}
      </div>
    </article>
  );
}

export default Slider;

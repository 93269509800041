import React from 'react';
import styles from './message.module.css';
import { TMessage } from '../../utils/types';

const Messaage: React.FC<TMessage> = ({children, style}) => {
  let styleResult;
  switch (style) {
    case 1:
      styleResult = styles.message1;
      break
    case 2:
      styleResult = styles.message2;
      break
    case 3:
      styleResult = styles.message3;
      break
    case 4:
      styleResult = styles.message4;
      break
    default:
      styleResult = styles.message1;
      break
  };

  return (
    <div className={`${styles.message} ${styleResult}`}>
      {children}
    </div>
  );
}

export default Messaage;

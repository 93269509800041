import React, { useRef } from 'react';
import styles from './notfound.module.css';
import PageBg from '../../components/pageBg/PageBg';
import ShadowTop from '../../components/shadowTop/shadowTop';
import ShadowBottom from '../../components/shadowBottom/shadowBottom';

import bg_404 from '../../images/404.svg';
import person_404 from '../../images/person_404.png';
import emoji_love from '../../images/emoji_love.png';
import { useNavigate } from 'react-router-dom';
import Message from '../../components/message/message';
import Notification from '../../components/notification/notification';

const NotFound: React.FC = () => {
  const [speak, setSpeak] = React.useState<boolean>(false);
  const [displayedText, setDisplayedText] = React.useState<string>('');
  const [messageStyle, setMessageStyle] = React.useState<number>(1);
  const [runSequence, setRunSequence] = React.useState<boolean>(false);
  const [viewAllText, setViewAllText] = React.useState<boolean>(false);
  const [personRedBg, setPersonRedBg] = React.useState<boolean>(false);

  const animationStoppedRef = useRef<boolean>(false);

  const [notificationTimeOutView, setNotificationTimeOutView] = React.useState<NodeJS.Timeout>();
  const [notificationView, setNotificationView] = React.useState<boolean>(false);

  const [notificationTimeOutHide, setNotificationTimeOutHide] = React.useState<NodeJS.Timeout>();
  const [notificationHide, setNotificationHide] = React.useState<boolean>(false);
  

  const delay: number = 3000;
  const promocode: string = "NOTFOUND";
  const text1: string = "Ой-ой! Кажется, кто-то наткнулся на статью 404!";
  const text2: string = "Не волнуйтесь, на первый раз обойдёмся предупреждением. В следующий раз будьте внимательнее и не теряйте ссылки!))";
  const text3: string = `А пока вот вам нешуточный промокод: ${promocode} Используйте его, чтобы найти верную дорогу обратно!`;

  const text4: string = "Вот вам нешуточный промокод: NOTFOUND Используйте его, чтобы найти верную дорогу обратно!";

  const navigate = useNavigate();
  const goToMain = async () => {
    if (viewAllText) {
      navigate('/');
    } else {
      setViewAllText(true);
      animationStoppedRef.current = true;
      setDisplayedText('');
      setPersonRedBg(true);
      displayTextBase("СТОП!!!", 4);
      setTimeout(async () => {
        setDisplayedText('');
        setPersonRedBg(false);
        await displayTextBase(text4, 3);
      }, 2000);
    }
  };
  const closeNotification = () => {
    clearTimeout(notificationTimeOutView);
    setNotificationHide(true);
    const timerId = setTimeout(() => {
      setNotificationView(false);
      setNotificationHide(false);
    }, 1000);

    setNotificationTimeOutView(timerId);
  };
  const copyPromocode = () => {
    navigator.clipboard.writeText(promocode);
    setNotificationView(true);
    clearTimeout(notificationTimeOutHide);

    const timerId = setTimeout(() => {
      closeNotification();
    }, 5000);
    setNotificationTimeOutHide(timerId);
  };

  const displayTextBase = (text: string, style: number): Promise<void> => {
    return new Promise((resolve) => {
      let index = 0;
      setMessageStyle(style);
      setSpeak(true);

      const interval = setInterval(() => {
        setDisplayedText((prev) => {
          if (index < text.length) {
            return prev + text[index++];
          } else {
            clearInterval(interval);
            setSpeak(false);
            resolve();
            return prev;
          }
        });
      }, 30);
    });
  };

  React.useEffect(() => {
    const displayText = (text: string, style: number): Promise<void> => {
      return new Promise((resolve) => {
        let index = 0;
        setMessageStyle(style);
        setSpeak(true);

        const interval = setInterval(() => {
          if (animationStoppedRef.current) {
            clearInterval(interval);
            setSpeak(false);
            resolve();
            return;
          }
          setDisplayedText((prev) => {
            if (index < text.length) {
              return prev + text[index++];
            } else {
              clearInterval(interval);
              setSpeak(false);
              if (text === text3) {setViewAllText(true)};
              resolve();
              return prev;
            }
          });
        }, 30);
      });
    };

    const runTextSequence = async () => {
      setDisplayedText('');
      await displayText(text1, 1);
      if (animationStoppedRef.current) return;
      setTimeout(async () => {
        if (animationStoppedRef.current) return;
        setDisplayedText('');
        await displayText(text2, 2);
        if (animationStoppedRef.current) return;
        setTimeout(async () => {
          if (animationStoppedRef.current) return;
          setDisplayedText('');
          await displayText(text3, 3);
        }, delay);
      }, delay);
    };

    if (!runSequence) {
      setRunSequence(true);
      runTextSequence();
    };

    return () => {
      animationStoppedRef.current = true;
    };
  }, []);

  return (
    <PageBg 
      type='notfound' 
      radial='empty'
      id='notfound'
      opacity={0.2}
    >
      <ShadowTop/>
      <ShadowBottom/>
      <img className={styles.bg_404} src={bg_404} alt=""/>
      <div className={styles.personContainer}>
        <div className={`${styles.personBg} ${personRedBg ? styles.personBgred : ''}`}/>
        <img className={`${styles.person_404} ${speak ? styles.person_404_speak : ''}`} src={person_404} alt="Чиби"/>
        <button 
          onClick={goToMain}
          type="button"
          className={styles.button}
        >
          <span className={styles.buttonCircle} aria-hidden="true">
            <span className={styles.buttonCircleIcon}></span>
          </span>
          <span className={styles.buttonText}>вернуться на главную</span>
        </button>
        <div className={styles.messageContainer}>
          <Message
            style={messageStyle}
          >
            {displayedText.split(/(\s+)/).map((word, index) => (
              word.toLowerCase() === promocode.toLowerCase() ? (
                <span key={`${messageStyle}_${index}_${word}_1`} className={styles.promocode} onClick={copyPromocode}>{word}<br/><br/></span>
              ) : (
                <React.Fragment key={`${messageStyle}_${index}_${word}_2`}>{word}</React.Fragment>
              )
            ))}
            {displayedText === text3 &&
              <img className={styles.emoji_love} src={emoji_love} alt="love"/>
            }
          </Message>
        </div>
      </div>
      {notificationView &&
        <div className={`${styles.notificationContainer} ${notificationHide ? styles.notificationContainerHide : ''}`}>
          <Notification
            type="copy"
            text="Скопировано в буфер"
            close={closeNotification}
          />
        </div>
      }
    </PageBg>
  );
};

export default NotFound;

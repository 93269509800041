import React from 'react';
import styles from './card.module.css';
import { TCard } from '../../utils/types';

const Card: React.FC<TCard> = ({children, type, size, step, title, description}) => {
  let styleType;
  switch (type) {
    case 'blue':
      styleType = styles.blue;
      break
    case 'violet':
      styleType = styles.violet;
      break
    case 'red':
      styleType = styles.red;
      break
    default:
      styleType = styles.blue;
      break
  };

  let styleSize;
  switch (size) {
    case 'small':
      styleSize = styles.small;
      break
    case 'large':
      styleSize = styles.large;
      break
    default:
      styleSize = styles.small;
      break
  };

  return (
    <article className={`${styleSize} ${styles.card} ${styleType}`}>
      <p className={styles.step_title}>{step}</p>
      <h2 className={styles.card_title}>{title}</h2>
      <p className={styles.card_description}>{description}</p>
      <div className={styles.cardButtons}>
        {children}
      </div>
    </article>
  );
}

export default Card;

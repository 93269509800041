import React from 'react';
import styles from './navbutton.module.css';
import { TNuvButton } from '../../utils/types';
import imageKey from '../../images/key.svg';
import imageForum from '../../images/forum.svg';

const NavButton: React.FC<TNuvButton> = ({children, image, link}) => {
  let img;
  switch (image) {
    case 'key':
      img = imageKey;
      break
    case 'forum':
      img = imageForum;
      break
    default:
      img = '';
      break
  };

  return ( 
    <a className={styles.link} href={link}>
      <img className={styles.img} src={img} alt="" />
      <p className={styles.text}>{children}</p>
    </a>
  );
}

export default NavButton;

import React from 'react';
import { useLocation } from "react-router-dom";
import styles from './donate.module.css';
import PageBg from '../../components/pageBg/PageBg';
import ShadowBottom from '../../components/shadowBottom/shadowBottom';
import Button from '../../components/button/Button';
import donate_person from '../../images/donate/donate_person.png';
import coin_1 from '../../images/donate/coin_1.png';
import coin_2 from '../../images/donate/coin_2.png';
import coin_3 from '../../images/donate/coin_3.png';
import Input from '../../components/input/Input';

import { getPaymentTypes, getUserId, getInvoice } from '../../utils/api'

import arrow_bottom from '../../images/donate/arrow_bottom.svg'
import icon_checkbox from '../../images/donate/icon_checkbox.svg';
import icon_wallet_orange from '../../images/donate/icon_wallet_orange.svg';
import icon_wallet_green from '../../images/donate/icon_wallet_green.svg';

export type TPayments = {
  name: string;
  description: string;
  code: string;
}

const userIdDefault = {
  input: "", 
  steam_id: "", 
  error: false,
  load: false,
};

const focusDefault = { 
  paytype: false,
  id: false,
  email: false,
  count: false,
};

const valueDefault = { 
  paytype: '',
  id: '',
  email: '',
  count: '',
  oferta: false,
  refund: false,
};

const valueValidateDefault = { 
  paytype: false,
  id: false,
  email: false,
  count: false,
};

function Donate() {
  const location = useLocation();

  const [payTypes, setPayTypes] = React.useState<TPayments[]>([]);
  const [idDisabled, setIdDisabled] = React.useState<boolean>(false);
  const [userId, setUserId] = React.useState<{ input: string; steam_id: string; error: boolean, load: boolean }>(userIdDefault);
  const [focus, setFocus] = React.useState<{ paytype: boolean; id: boolean; email: boolean; count: boolean; }>(focusDefault);
  const [value, setValue] = React.useState<{ paytype: string; id: string; email: string; count: number | string; oferta: boolean; refund: boolean; }>(valueDefault);
  const [valueValidate, setValueValidate] = React.useState<{ paytype: boolean; id: boolean; email: boolean; count: boolean; }>(valueValidateDefault);
  const [formReady, setFormReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (payTypes.length === 0) {
      getPaymentTypes()
        .then((result: TPayments[]) => {
          setPayTypes(result);
        })
        .catch((error) => {
          console.log('ERROR: PAYMENTTYPES');
          console.log(error);
        });
    }

    const searchParams = new URLSearchParams(location.search);
    const pid = searchParams.get('pid');
    const amount = searchParams.get('amount');
    if (pid && amount) {
      setIdDisabled(true);
      setValue({ 
        ...value, 
        id: pid, 
        count: amount
      });
      setValueValidate({ 
        ...valueValidate,
        id: validateName(pid),
        count: (((Number(amount) >= 50) && (Number(amount) <= 50005)) ? true : false) 
      })
    } else {
      if (pid) {
        setIdDisabled(true);
        setValue({ ...value, id: pid});
        setValueValidate({ ...valueValidate, id: validateName(pid) })
      }
      if (amount) {
        setValue({ ...value, count: amount});
        setValueValidate({ ...valueValidate, count: (((Number(amount) >= 50) && (Number(amount) <= 50005)) ? true : false) })
      }
    }
  },[]);

  React.useEffect(() => {
    if (valueValidate.id && !focus.id && !userId.load && (value.id !== userId.input)) {
      setUserId({
        ...userId,
        load: true,
      });

      getUserId(value.id)
        .then((result) => {
          if (result.steam_id) {
            setUserId({
              input: value.id,
              steam_id: result.steam_id,
              error: false,
              load: false,
            });
          } else {
            setUserId({
              input: "",
              steam_id: "",
              error: true,
              load: false,
            });

            if (idDisabled) {setIdDisabled(false)};
            setValueValidate({ ...valueValidate, id: false });
            setValue({ ...value, id: `${value.id} (Пользователь не найден)` });
          }
        })
        .catch((error) => {
          console.log('ERROR: USERDATA');
          console.log(error);
        });
    }
  }, [valueValidate, focus]);

  React.useEffect(() => {
    setFormReady(isValidate());
  }, [valueValidate, value, userId]);

  const validateEmail = (email: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  const validateName = (input: string) => {
    if (/^\d+$/.test(input) && input.length === 17 && input.startsWith('7656119')) {
      return true;
    }
  
    if (/^[a-zA-Z][a-zA-Z\s.]{3,22}[a-zA-Z]$/.test(input)) {
      return true;
    }
  
    return false;
  }

  const isValidate = () => {
    for (const key in valueValidate) {
      if (!valueValidate[key as keyof typeof valueValidate]) {
        return false;
      }
    }
    if (!value.oferta) {return false}
    if (!value.refund) {return false}
    if (userId.steam_id === "") {return false}
    return (true);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inKey = e.target.name;
    const inValue = e.target.value;

    switch (inKey) {
      case 'oferta':
        setValue({ ...value, oferta: !value.oferta });
        break
      case 'refund':
        setValue({ ...value, refund: !value.refund });
        break
      default:
        setValue({ ...value, [inKey]: inValue });
        break
    }

    if ((e.target.name === 'id') && (userId.steam_id !== "")) {
      setUserId(userIdDefault);
    }

    if (!(e.target.name === '')) {
      switch (e.target.name) {
        case 'paytype':
          setValueValidate({ ...valueValidate, [inKey]: true })
          break
        case 'id':
          setValueValidate({ ...valueValidate, [inKey]: validateName(inValue) })
          break
        case 'email':
          setValueValidate({ ...valueValidate, [inKey]: validateEmail(inValue) })
          break
        case 'count':
          setValueValidate({ ...valueValidate, [inKey]: (((Number(inValue) >= 50) && (Number(inValue) <= 50005)) ? true : false) })
          break
        default:
          break
      }
    }
  };

  const onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue({ ...value, "paytype": e.target.value });

    const payData = payTypes.find((inItem, inIndex, inArray) => {
      return (inItem.code === e.target.value);
    });
    if (payData) {
      setValueValidate({ ...valueValidate, "paytype": !(payData.code === "") })
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    getInvoice(value.paytype, Number(value.count), userId.steam_id, value.email)
      .then((result) => {
        if (result.invoice) {
          if (result.invoice.paymentLink) {
            window.location.href = result.invoice.paymentLink;
          };
        };
      })
      .catch((error) => {
        console.log('ERROR: INVOICE');
        console.log(error);
      });
  };

  return (
    <PageBg 
      type='donate' 
      radial='empty'
      id='donate'
    >
      <div className={styles.bg_shadow}/>
      <img alt="" src={coin_3} className={styles.img_coin_3}/>
      <img alt="" src={donate_person} className={styles.img_person}/>
      <img alt="" src={coin_2} className={styles.img_coin_2}/>
      <img alt="" src={coin_1} className={styles.img_coin_1}/>
      <ShadowBottom/>
      <div className={styles.mainContainer}>
        <form className={styles.form} onSubmit={onSubmit}>
          <div className={styles.form_background}/>
          <h1 className={styles.formTitle}>Приобрести AP</h1>
          <p className={styles.description}>AP - Это внутриигровая валюта (amazing point), которую можно потратить на улучшения персонажа внутри игры.</p>
          <div className={styles.selectContainer}> 
            {
                (valueValidate.paytype ? (
                  <img src={icon_wallet_green} alt="" className={styles.selectLeftImage} />
                ) : (
                  <img src={icon_wallet_orange} alt="" className={styles.selectLeftImage} />
                ))
            }
            {
                (valueValidate.paytype ? (
                  <img src={icon_checkbox} alt="" className={styles.selectRightImage} />
                ) : (
                  <img src={arrow_bottom} alt="" className={styles.selectRightImage} />
                ))
            }
            <select name="paytype" value={value.paytype} className={styles.select} onChange={onChangeSelect} required> 
              <option key="DEFAULT_TYPE" value="" disabled hidden>Выберите способ оплаты</option>
              {
                payTypes.map((inItem, inIndex, inArray) => {
                  return(<option key={inItem.code} value={inItem.code}>{inItem.name}</option>)
                })
              }
            </select>
          </div>
          <div className={styles.mt_input_top}>
            <Input 
              type='text'
              name='id'
              placeHolder='Введите имя вашего персонажа или SteamID (64)'
              onChange={onChange}
              value={value.id}
              icon='person'
              extraClass='mt-12'
              isValid={valueValidate.id && userId.steam_id !== "" && !userId.error}
              focus={focus.id}
              focusIn={() => {setFocus({ ...focus, id: true })}}
              focusOut={() => {setFocus({ ...focus, id: false })}}
              disabled={idDisabled}
            />
          </div>
          <div className={styles.mt_input_top}>
            <Input 
              type='email'
              name='email'
              placeHolder='Введите вашу почту'
              onChange={onChange}
              value={value.email}
              icon='mail'
              extraClass='mt-12'
              isValid={valueValidate.email}
              focus={focus.email}
              focusIn={() => {setFocus({ ...focus, email: true })}}
              focusOut={() => {setFocus({ ...focus, email: false })}}
            />
          </div>
          <div className={styles.mt_input_top}>
            <Input 
              type='number'
              name='count'
              placeHolder='Введите сумму пополнения (1 рубль = 1 AP)'
              onChange={onChange}
              value={value.count}
              icon='count'
              extraClass='mt-12'
              isValid={valueValidate.count}
              focus={focus.count}
              focusIn={() => {setFocus({ ...focus, count: true })}}
              focusOut={() => {setFocus({ ...focus, count: false })}}
            />
          </div>

          {/* CHECKBOXES */}
            <div className={styles.checkboxOferta}>
              <label>
                <input 
                  className={styles.checkbox}
                  type="checkbox" 
                  name="oferta"
                  checked={value.oferta}
                  onChange={onChange}
                />
              </label>
              <p className={styles.textCheckbox}>Я ознакомился с <a className={styles.ofertaLink} href='https://amazingrp.ru/files/public-offer.pdf' target="_ blank">условиями оферты</a></p>
            </div>
            <div className={styles.checkboxRefund}>
              <label>
                <input 
                  className={styles.checkbox}
                  type="checkbox" 
                  name="refund"
                  checked={value.refund}
                  onChange={onChange}
                />
              </label>
              <p className={styles.textCheckbox}>Я согласен приобрести amazing point (цифровая услуга) на свою учетную запись AmazingLife - RPG, понимая, что после этого не смогу расторгнуть договор или получить возмещение.</p>
            </div>
            <div className={styles.buttonContainer}>
              <Button
                size={'large'}
                color={'blackOrange'}
                text={'medium'}
                borderRadius={10}
                type="submit"
                disable={!formReady}
              >
                Перейти к оплате
              </Button>
            </div>
        </form>
      </div>
    </PageBg>
  );
}

export default Donate;

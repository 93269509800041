import React from 'react';
import styles from './slidercontainer.module.css';
import PageBg from '../../components/pageBg/PageBg';
import arrow_left from '../../images/arrow_left.svg'
import arrow_right from '../../images/arrow_right.svg'
import Slider from '../../components/slider/Slider';
import ShadowTop from '../../components/shadowTop/shadowTop';
import ShadowBottom from '../../components/shadowBottom/shadowBottom';
import Button from '../../components/button/Button';
import { TScrollPage } from '../../utils/types';

const sliderLeftData = [
  {
    key: 'civilian',
    title: 'ГРАЖДАНСКИЙ',
    description: 'Гражданский мир – это постоянное стремление к улучшению жизни и общества в целом. В нем каждый человек играет свою роль, будучи звеном в цепи общего благополучия. Но иногда, чтобы добиться своих целей, приходится взвешивать различные варианты и принимать непростые решения.',
    lineColor: 'green',
    buttonColor: 'green',
    buttonText: 'СТАТЬ ПЕРВЫМ'
  },
  {
    key: 'police',
    title: 'ПОЛИЦЕЙСКИЙ',
    description: 'Роль полицейского состоит в защите общества от преступности и поддержании законного порядка. Ваша работа часто связана с самыми опасными и сложными задачами, которые требуют не только профессионализма, но и хладнокровия в самых критических ситуациях. Однако, даже с таким послужным списком, всегда есть место для роста и совершенствования.',
    lineColor: 'blue',
    buttonColor: 'blue',
    buttonText: 'ЗАЩИТИТЬ АЛТИС'
  },
  {
    key: 'medic',
    title: 'ВРАЧ',
    description: 'Роль врача является одной из самых значимых и ответственных в обществе. Их работа охватывает широкий спектр обязанностей, включая диагностику, лечение, профилактику заболеваний, а также обеспечение медицинской помощи в экстренных ситуациях. Они также могут выполнять медицинские процедуры, назначать лекарства и проводить операции.',
    lineColor: 'red',
    buttonColor: 'red',
    buttonText: 'СТАТЬ ИНТЕРНОМ'
  },
  {
    key: 'rebel',
    title: 'ПОВСТАНЕЦ',
    description: 'Роль повстанца представляет собой участие в преступных действиях, таких как грабежи, аферы, контрабанда или другие формы незаконной деятельности. Повстанцы часто собираются в банды, где каждый член выполняет свою специализированную роль с целью выгоды, укрепления своей власти и авторитета.',
    lineColor: 'olive',
    buttonColor: 'olive',
    buttonText: 'НАЧАТЬ БОРЬБУ'
  },
  {
    key: 'zpm',
    title: 'Иследователь ЗПМ',
    description: 'Добро пожаловать в Зону Падения Метеорита (ЗПМ) - загадочное и опасное место, где реальность сливается с фантазией. Эта зона обладает своей уникальной атмосферой и впечатляющим арсеналом аномалий, которые делают ее как опасным местом для исследования, так и источником потенциально ценных ресурсов.',
    lineColor: 'violet',
    buttonColor: 'violet',
    buttonText: 'ПОСЕТИТЬ ЗОНУ'
  }
]

const SliderContainer: React.FC<TScrollPage> = ({reference}) => {
  const [active, setActive] = React.useState(({0: false, 1: false, 2: false, 3: false, 4: false}));
  const [index, setIndex] = React.useState(0);

  const goToInstall = () => {
    const section = document.getElementById('howtoinstall');
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <PageBg 
      type='slider' 
      radial='empty'
      id='slidercontainer'
      reference={reference}
    >
      <div 
        className={styles.backgrounds}
        style={{
          width: `${100 * sliderLeftData.length}%`
        }}
      >
        {/* SLIDER #1 */}
        <div
          className={styles.background}
          style={{
            translate: `${-100 * index}%`,
            width: `${100 / sliderLeftData.length}%`
          }}
        >
          <div className={`${styles.bg_shadow} ${styles.bg_1_1}`}/>
          <img
            alt='' 
            className={`${styles.img_1_1} ${active[0] ? styles.img_1_1_active : ''}`}
            src={require('../../images/slider_1_1.png')}
          />
          <img 
            alt=''
            className={`${styles.img_1_2} ${active[0] ? styles.img_1_2_active : ''}`}
            src={require('../../images/slider_1_2.png')}
          />
        </div>
        {/* SLIDER #2 */}
        <div
          className={styles.background}
          style={{
            translate: `${-100 * index}%`,
            width: `${100 / sliderLeftData.length}%`
          }}
        >
          <div
           className={`${styles.bg_shadow} ${styles.bg_2_1} ${active[1] ? styles.bg_2_1_active : ''}`}
          />
          <img
            alt='' 
            className={`${styles.img_2_1} ${active[1] ? styles.img_2_1_active : ''}`}
            src={require('../../images/slider_2_1.png')}
          />
          <img 
            alt=''
            className={`${styles.img_2_2} ${active[1] ? styles.img_2_2_active : ''}`}
            src={require('../../images/slider_2_2.png')}
          />
        </div>
        {/* SLIDER #3 */}
        <div
          className={styles.background}
          style={{
            translate: `${-100 * index}%`,
            width: `${100 / sliderLeftData.length}%`
          }}
        >
          <div className={`${styles.bg_shadow} ${styles.bg_3_1}`}/>
          <img
            alt='' 
            className={`${styles.img_3_2} ${active[2] ? styles.img_3_2_active : ''}`}
            src={require('../../images/slider_3_2.png')}
          />
          <img
            alt='' 
            className={`${styles.img_3_1} ${active[2] ? styles.img_3_1_active : ''}`}
            src={require('../../images/slider_3_1.png')}
          />
        </div>
        {/* SLIDER #4 */}
        <div
          className={styles.background}
          style={{
            translate: `${-100 * index}%`,
            width: `${100 / sliderLeftData.length}%`
          }}
        >
          <div className={`${styles.bg_shadow} ${styles.bg_4_1}`}/>
          <img
            alt='' 
            className={`${styles.img_4_1} ${active[3] ? styles.img_4_1_active : ''}`}
            src={require('../../images/slider_4_1.png')}
          />
          <img 
            alt=''
            className={`${styles.img_4_2} ${active[3] ? styles.img_4_2_active : ''}`}
            src={require('../../images/slider_4_2.png')}
          />
        </div>
        {/* SLIDER #5 */}
        <div
          className={styles.background}
          style={{
            translate: `${-100 * index}%`,
            width: `${100 / sliderLeftData.length}%`
          }}
        >
          <div className={`${styles.bg_shadow} ${styles.bg_5_1}`}/>
          <img
            alt='' 
            className={`${styles.img_5_3} ${active[4] ? styles.img_5_3_active : ''}`}
            src={require('../../images/slider_5_3.png')}
          />
          <img
            alt='' 
            className={`${styles.img_5_1} ${active[4] ? styles.img_5_1_active : ''}`}
            src={require('../../images/slider_5_1.png')}
          />
          <img 
            alt=''
            className={`${styles.img_5_2} ${active[4] ? styles.img_5_2_active : ''}`}
            src={require('../../images/slider_5_2.png')}
          />
        </div>
      </div>
      <ShadowTop/>
      <ShadowBottom/>
      { (index > 0) &&
        <img
          alt=''
          className={`${styles.arrow} ${styles.arrow_left}`}
          src={arrow_left} 
          onClick={() => {
            if (index > 0) {
              setIndex(index - 1);
            }
          }}
        />
      }
      { (index < (sliderLeftData.length - 1)) &&
        <img
          alt=''
          className={`${styles.arrow} ${styles.arrow_right}`}
          src={arrow_right}
          onClick={() => {
            if (index < (sliderLeftData.length - 1)) {
              setIndex(index + 1);
            }
          }}
        />
      }
      <div
        className={styles.articles}
        style={{
          width: `${100 * sliderLeftData.length}%`
        }}
      >
        {
          sliderLeftData.map((arItem, arIndex, array) => {
            return (
              <Slider 
                key={arItem.key}
                translate={-100 * index}
                setActive={(result) => {setActive({...active, [arIndex]: result})}} 
                title={arItem.title}
                line={arItem.lineColor}
                description={arItem.description}
                button={
                  <Button size={'medium'} color={arItem.buttonColor} text={'normal'} borderRadius={15} type="button" disable={false} onClick={goToInstall}>{arItem.buttonText}</Button>
                }
              />
            );
          })
        }
      </div>
    </PageBg>
  );
}

export default SliderContainer;

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import styles from './app.module.css';

import Main from '../../page/main/Main';
import NotFound from '../../page/notfound/NotFound';
import AppHeader from '../appheader/AppHeader';
import SliderContainer from '../../page/slidercontainer/SliderContainer';
import Donate from '../../page/donate/Donate';
import HowToInstall from '../../page/howtoinstall/HowToInstall';
import Footer from '../footer/Footer';
import Advantages from '../../page/advantages/Advantages';
import Scrollbar from '../scrollbar/Scrollbar';
import { TServerStatus } from '../../utils/types';
import { getServerState } from '../../utils/api';

const serverStatusDefault: TServerStatus = {
  status: false, 
  players: 0
};

function App() {
  const [page, setPage] = React.useState(1);
  const [serverStatus, setServerStatus] = React.useState(serverStatusDefault);
  const [serverStatusLoad, setServerStatusLoad] = React.useState(false);

  const mainRef = React.useRef(null);
  const advantagesRef = React.useRef(null);
  const sliderContainerRef = React.useRef(null);
  const howToInstallRef = React.useRef(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            switch (entry.target) {
              case mainRef.current:
                setPage(1);
                break;
              case advantagesRef.current:
                setPage(2);
                break;
              case sliderContainerRef.current:
                setPage(3);
                break;
              case howToInstallRef.current:
                setPage(4);
                break;
              default:
                break;
            }
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.01
      }
    );

    if (mainRef.current) {
      observer.observe(mainRef.current);
    }
    if (advantagesRef.current) {
      observer.observe(advantagesRef.current);
    }
    if (sliderContainerRef.current) {
      observer.observe(sliderContainerRef.current);
    }
    if (howToInstallRef.current) {
      observer.observe(howToInstallRef.current);
    }

    if (!serverStatusLoad) {
      setServerStatusLoad(true);
      getServerState()
        .then((result: TServerStatus) => {
          setServerStatus(result);
        })
        .catch((error) => {
          setServerStatus({status: false, players: 0});
          console.log('ERROR: SERVERSTATUS');
          console.log(error);
        });
    }

    return () => {
      if (mainRef.current) {
        observer.unobserve(mainRef.current);
      }
      if (advantagesRef.current) {
        observer.unobserve(advantagesRef.current);
      }
      if (sliderContainerRef.current) {
        observer.unobserve(sliderContainerRef.current);
      }
      if (howToInstallRef.current) {
        observer.unobserve(howToInstallRef.current);
      }
    };
  }, []);

  return (
    <div className={styles.all}>
      <Routes>
        <Route path="/" element={
            <main className={styles.main}>
              <AppHeader/>
              <Scrollbar page={page} />
              <Main reference={mainRef}/>
              <Advantages reference={advantagesRef}/>
              <SliderContainer reference={sliderContainerRef}/>
              <HowToInstall reference={howToInstallRef}/>
              <Footer type="main" serverStatus={serverStatus} />
            </main>
        } />
        <Route path="/donate" element={
            <main className={styles.main}>
              <AppHeader/>
              <Donate />
              <Footer type="donate" serverStatus={serverStatus} />
            </main>
        } />
        <Route path="*" element={
          <main className={styles.main}>
            <NotFound />
          </main>
        } />
      </Routes>
    </div>
  );
}

export default App;

import React from 'react';
import styles from './footer.module.css';

import vk from '../../images/social/vk.svg'
import discord from '../../images/social/discord.svg'

import mir from '../../images/pay/mir.svg'
import sbp from '../../images/pay/sbp.svg'

import { TFooter } from '../../utils/types';

const Footer: React.FC<TFooter> = ({ type, serverStatus }) => {
  const [isStatic, setStatic] = React.useState<boolean>(false);

  React.useEffect(() => {
    setStatic(((type === 'donate') && (window.screen.availHeight <= 1080)) ? true : false)
  }, [type]);

  return (
    <footer className={`${styles.footer} ${isStatic ? styles.footerStatic : ''}`}>
      <div className={styles.mainContainer}>
        <div className={styles.topContainer}>
          <div className={styles.socials}>
            <a href="https://vk.com/amazingliferpg" target="_ blank" className={styles.imgLinks}><img alt="vk" className={styles.imgSocial} src={vk} /></a>
            <a href="http://discord.amazingrp.ru" target="_ blank" className={styles.imgLinks}><img alt="discord" className={styles.imgSocial} src={discord} /></a>
          </div>
          <div className={styles.links}>
            <a href="https://amazingrp.ru/forum/" className={styles.link}>Форум</a>
            <a href="https://lk.amazingrp.ru/" className={styles.link}>Личный кабинет</a>
            <a href="/donate" className={styles.link}>Пополнить счет</a>
          </div>
          <div className={styles.documents}>
            <a href="https://amazingrp.ru/files/user-agreement.pdf" target="_ blank" className={styles.link}>Пользовательское соглашение</a>
            <a href="https://amazingrp.ru/files/public-offer.pdf" target="_ blank" className={styles.link}>Публичная оферта</a>
            <a href="https://amazingrp.ru/files/personal-data-policy.pdf" target="_ blank" className={styles.link}>Политика конфиденциальности</a>
          </div>
          <div className={styles.contacts}>
            <a href="https://discord.gg/YaMS8dUxHp" target="_ blank" className={styles.link}>Поддержка в Discord</a>
            <a href="https://vk.com/im?sel=-135945913" target="_ blank" className={styles.link}>Поддержка в VK</a>
            <a href="https://amazingrp.ru/forum/forums/pravila-instrukcii.48/" target="_ blank" className={styles.link}>Правила и инструкции</a>
          </div>
          <div className={styles.payTypes}>
            <img alt="Мир" className={styles.img} src={mir} />
            <img alt="СБП" className={styles.img} src={sbp} />
          </div>
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.company}>
            <p className={styles.text}>ИП ВОЛОГУЗОВ АЛЕКСАНДР АНДРЕЕВИЧ</p>
            <p className={styles.text}>ИНН: 330576809809</p>
            <p className={styles.text}>ОГРНИП: 324330000033371</p>
          </div>
          <p className={styles.textDisclamer}>AmazingLife - RPG не является аффилированным лицом и не одобрено Bohemia Interactive или другим правообладателем. Все используемые товарные знаки принадлежат их соответствующим владельцам.</p>
          <p className={`${styles.text} ${styles.serverStatusContainer}`}>Статус сервера <span className={`${styles.serverStatus} ${serverStatus.status ? styles.serverStatusOk : styles.serverStatusKo}`}></span></p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React from 'react';
import styles from './cardbutton.module.css';
import { TCardButton } from '../../utils/types';

import icon_download from '../../images/cards/icon_download.svg'
import icon_teamspeak from '../../images/cards/icon_teamspeak.svg'
import icon_microphone from '../../images/cards/icon_microphone.svg'
import icon_steam from '../../images/cards/icon_steam.svg'

const CardButton: React.FC<TCardButton> = ({onClick, children, color, type, icon, size, disable}) => {

  let styleColor;
  switch (color) {
    case 'blue':
      styleColor = styles.blue;
      break
    case 'violet':
      styleColor = styles.violet;
      break
    case 'red':
      styleColor = styles.red;
      break
    default:
      styleColor = styles.blue;
      break
  };

  let styleIcon;
  switch (icon) {
    case 'download':
      styleIcon = icon_download;
      break
    case 'teamspeak':
      styleIcon = icon_teamspeak;
      break
    case 'microphone':
      styleIcon = icon_microphone;
      break
    case 'steam':
      styleIcon = icon_steam;
      break
    default:
      styleIcon = icon_download;
      break
  };

  let styleSize;
  switch (size) {
    case 'small':
      styleSize = styles.small;
      break
    case 'medium':
      styleSize = styles.medium;
      break
    default:
      styleSize = styles.small;
      break
  };

  return (
    <button 
      className={`${styles.btn} ${styleColor} ${styleSize}`} 
      onClick={onClick}
      type={type}
      disabled={disable}
    >
      <img alt='' className={styles.icon} src={styleIcon} />
      {children}
    </button>
  );
}

export default CardButton;
